body {
    direction: rtl;
    font-family: 'Poppins', 'Arial', sans-serif;
    background-color: #202020;
    transition: background-color 0.5s ease;
}

main {
    display: none;
}

.main-body {
    display: none;
}

.main-bodyv2 {
    display: block;
}

.main-display {
    display: grid;
}

.count {
    overflow-y: hidden;
    padding: 32px 96px;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    z-index: 100;

    &-logo {
        text-align: left;
        margin-bottom: 250px;
    }

    &-number {
        text-align: center;

        &-text {
            text-align: center;
            color: #FFF;
            font-size: 80px;
            font-weight: 700;
            line-height: 120px;
        }
    }
}

.header {
    padding: 40px 0px 80px 0px;
    direction: rtl;
    &-inner {
        display: flex;
        justify-content: space-between;
        padding-left: 96px;
        padding-right: 96px;
        align-items: center;
    }

    &-right {
        text-align: right;
    }

    &-btn {
        border: none;
        cursor: pointer;
        background-color: initial;
    }

    &-logo {
        width: 134px;
    }

    &-menu-logo {
        width: 30px;
        height: auto;
    }

    &-left {
        text-align: left;
        img{
            width: auto;
            height: auto;
        }
    }

    &-hidden {
        visibility: hidden;
    }
}

.sidebar {
    padding: 40px 96px 96px 96px;

    &-top {
        display: flex;
        justify-content: space-between;
        margin-bottom: 60px;
        align-items: baseline;
    }

    &-close {
        cursor: pointer;
        width: 30px;
        height: 30px;

        &-logo {
            width: 30px;
        }
    }

    &-logo {
        height: 40px;
        img{
            width: auto;
            height: auto;
        }
    }

    &-text-color {
        color: #202020 !important;
        font-size: 48px;
        font-weight: 700;
        line-height: 72px;
    }

    &-link {
        &:not(:last-child) {
            margin-bottom: 28px !important;
        }
    }

    &-text{
        direction: ltr;
    }
}

@keyframes slideIn {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}
@keyframes slideInMobile {
    from { transform: translateX(-300%); }
    to { transform: translateX(0); }
}

.hero {
    text-align: center;
    background-image: url("../../../images/hero_bg.webp");
    background-size: contain;
    background-repeat: no-repeat;
    padding-bottom: 186px;
    // animation: slideIn 1s ease forwards;
    @media (min-width: 1440px) {
        background-size: cover;
        height: 100vh;
    }
    @media (max-width: 1300px) {
        background-size: cover;
    }
    
    &-wrapper {
        max-width: 1248px;
        margin: 0 auto;
        direction: ltr;
    }

    &-title {
        max-width: 600px;
        margin: 0 auto;

        &-img {
            width: 70%;
            height: auto;
            mix-blend-mode: overlay;
        }
    }


    &-text {
        color: #FFF;
        font-size: 28px;
        font-weight: 500;
        line-height: 48px;
        margin-bottom: 40px;
        margin-top: 24px;
    }

    &-btn {
        padding: 16px 32px !important;
        background-color: #FFF !important;
        border-radius: 32px !important;
        border: none;

        &-text {
            color: #000;
            font-size: 20px;
            font-weight: 700;
            line-height: 32px;
        }
    }
}

.begin {
    text-align: center;
    padding: 128px 48px 128px 48px;
    &-desktop {
        direction: ltr;

        @media (max-width: 1240px) {
            display: none;
        }
    }
    &-tablet {
        display: none;
        direction: ltr;
        direction: ltr;
        text-align: left;
        color: black;
        font-size: 48px;
        font-weight: 400;
        line-height: 80px;
        max-width: 960px;
        margin: 0 auto;
        text-align: left;
        @media (max-width: 1240px) {
            display: block;
        }
        @media (max-width: 720px) {
            display: none;
        }
    }
    &-mobile {
        display: none;
        direction: ltr;
        text-align: left;
        font-size: 24px;
        @media (max-width: 720px) {
            display: block;
        }
    }

    &-title {
        margin-bottom: 16px;
        color: #202020;
        font-size: 80px;
        font-weight: 700;
        line-height: 120px;
    }

    &-subject {
        margin-bottom: 80px;
        color: #FFF;
        font-size: 80px;
        font-weight: 700;
        line-height: 120px;
    }

    &-text {
        direction: ltr;
        text-align: left;
        color: white;
        font-size: 48px;
        font-weight: 400;
        line-height: 80px;
        max-width: 960px;
        margin: 0 auto;
    }
    
}
.reveal-type{
    direction: ltr;
    text-align: left;
    color: #8E8E95;
    font-size: 48px;
    font-weight: 400;
    line-height: 80px;
    max-width: 960px;
    margin: 0 auto;
}

.service {
    padding: 128px 48px;
    direction: ltr;

    &-wrapper {
        max-width: 1248px;
        margin: 0 auto;
    }

    &-re {
        display: none;
        white-space: nowrap;

        &-inner {
            display: flex;
            overflow-x: auto;
            overflow-y: hidden;
            -ms-overflow-style: none;
            /* IE and Edge */
            scrollbar-width: none;
            /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &-line {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 24px;
        }
    }

    &-title-box {
        width: 50%;
        margin-right: 24px;
    }

    &-title {
        color: #202020;
        font-size: 80px;
        font-weight: 700;
        line-height: 120px;
    }

    // .box-left {
    //     margin-left: 24px;
    // }

    &-box {
        width: 50%;
        background-color: rgba(0, 0, 13, 0.04);
        border-radius: 40px;
        padding: 48px;

        &:last-child {
            margin-left: 24px;
        }

        &-icon {
            display: flex;
            margin-left: auto;
            margin-bottom: -32px;
        }

        &-number {
            display: flex;
        }

        &-title {
            text-align: left;
            color: #202020;
            font-size: 48px;
            font-weight: 700;
            line-height: 72px;
            margin-bottom: 118px;
        }

        &-titlev2 {
            text-align: left;
            color: #202020;
            font-size: 42px;
            font-weight: 700;
            line-height: 55px;
            margin-bottom: 48px;
        }

        &-titlev3 {
            text-align: left;
            color: #202020;
            font-size: 48px;
            font-weight: 700;
            line-height: 72px;
            margin-bottom: 48px;
        }

        &-desc {
            text-align: left;
            color: #202020;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
        }

    }

}

.video {
    padding-top: 128px;
    padding-bottom: 128px;
    position: relative;
    overflow-x: hidden;
    &-mobile{
        display: none;
    }
    &-title {
        color: #FFF;
        font-size: 80px;
        font-weight: 700;
        line-height: 120px;
        margin-bottom: 80px;
        text-align: center;
    }

    &-style {
        width: 100%;
        border-radius: 40px;
    }

    .swiper-slide {
        width: 70%;
        opacity: 0.2;
        transition: opacity 0.3s;
    }

    .swiper-slide-active {
        opacity: 1;
    }
}

.question {
    direction: ltr;
    text-align: center;
    padding: 128px 96px 96px 96px;

    &-wrapper {
        max-width: 960px;
        margin: 0 auto;
    }

    &-title {
        color: #202020;
        font-size: 80px;
        font-weight: 700;
        line-height: 128px;
        margin-bottom: 80px;
    }

    &-box {
        margin-bottom: 256px;
    }

    &-list {
        background-color: rgba(0, 0, 13, 0.04);
        padding: 32px;
        border-radius: 20px;
        cursor: pointer;
        transition: 0.3s ease;

        &:hover {
            background-color: #fff;
        }

        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &-block {
        display: flex;
        justify-content: space-between;
    }

    &-text {
        color: #000;
        font-size: 24px;
        font-weight: 700;
        line-height: normal;
        text-align: left;
    }

    &-hidden {
        display: none;
        color: #202020;
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        text-align: left;
        margin-top: 24px;
    }

    &-add {
        width: 36px;
        height: 36px;
    }

    &-close {
        display: none;
        width: 36px;
        height: 36px;
    }

    &-img {
        max-width: 1248px;
        text-align: center;
        background-image: url("../../../images/hero_bg.webp");
        background-size: cover;
        background-repeat: no-repeat;
        padding: 124px 264px 108px 264px;
        border-radius: 40px;

        @media (max-width: 990px) {
            padding: 64px 32px;
            border-radius: 20px;
        }

        &-title {
            color: #FFF;
            font-size: 80px;
            font-weight: 700;
            line-height: 120px;
            margin-bottom: 64px;
        }
    }

    &-parallax-mobile {
        display: none;
    }
}

.footer {
    background-color: #202020;
    padding: 96px;
    display: flex;
    justify-content: space-between;
    direction: ltr;
    &-right {
        text-align: left;
        &-text {
            color: #FFF;
            font-size: 48px !important;
            font-weight: 700;
            line-height: 72px;
            margin-bottom: 40px !important;
        }
    }

    &-left {
        margin-left: auto;
        text-align: left;
        &-address {
            position: relative;
            padding: 24px;
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 20px;

            &:hover {
                background: rgba(255, 255, 255, 0.08);

                .footer-link-logo {
                    display: block;
                }
            }
        }

        &-telephone {
            position: relative;
            padding: 24px;
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 20px;

            &:hover {
                background: rgba(255, 255, 255, 0.08);

                .footer-link-logo {
                    display: block;
                }
            }
        }

        &-email {
            position: relative;
            padding: 24px;
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 20px;

            &:hover {
                background: rgba(255, 255, 255, 0.08);

                .footer-link-logo {
                    display: block;
                }
            }
        }

        &-link {
            display: inline-block;

            &:not(:last-child) {
                margin-bottom: 56px;
            }
        }

        &-title {
            color: rgb(255, 255, 255);
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            margin-bottom: 8px;
            opacity: 0.6;
        }

        &-text {
            color: #FFF;
            font-size: 24px;
            font-weight: 400;
            line-height: 36px;
            direction: ltr;
            text-align: left;
            display: inline-block;
        }
    }

    &-link-logo {
        display: none;
        position: absolute;
        right: 12px;
        top: 12px;
        transition: 0.3s ease;
        transform: rotate(90deg);
    }
}

// image to content section begin
// image to content 
.contentv6-open {
    overflow: hidden;
    height: 100vh;
}

.oh {
    position: relative;
    overflow: hidden
}

.oh__inner {
    will-change: transform;
    display: inline-block;
}

.line {
    transform-origin: 0;
    white-space: nowrap;
    will-change: transform;
}

//Page Loader
.js .loading::before,
.js .loading::after {
    content: '';
    position: fixed;
    z-index: 1000;
}

.js .loading::before {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #D6D5E3;
}

.js .loading::after {
    top: 50%;
    left: 50%;
    width: 60px;
    height: 60px;
    margin: -30px 0 0 -30px;
    border-radius: 50%;
    opacity: 0.4;
    background: var(--color-link);
    animation: loaderAnim 0.7s linear infinite alternate forwards;
}

@keyframes loaderAnim {
    to {
        opacity: 1;
        transform: scale3d(0.5, 0.5, 1);
    }
}

a {
    text-decoration: none;
    color: var(--color-link);
    outline: none;
    cursor: pointer;
}

a:hover {
    color: var(--color-link-hover);
    outline: none;
}

/* Better focus styles from https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible */
a:focus {
    /* Provide a fallback style for browsers
	 that don't support :focus-visible */
    outline: none;
    background: lightgrey;
}

a:focus:not(:focus-visible) {
    /* Remove the focus indicator on mouse-focus for browsers
	 that do support :focus-visible */
    background: transparent;
}

a:focus-visible {
    /* Draw a very noticeable focus style for
	 keyboard-focus on browsers that do support
	 :focus-visible */
    outline: 2px solid red;
    background: transparent;
}

.unbutton {
    background: none;
    border: 0;
    padding: 0;
    margin: 0;
    font: inherit;
    fill: currentColor;
    cursor: pointer;
    color: inherit;
    display: flex;
    align-items: center;
}

.unbutton:focus {
    outline: none;
}

.unbutton span:first-child {
    margin-right: 1rem;
}

.unbutton span:last-child {
    margin-left: 1rem;
}

.frame {
    grid-area: frame;
    padding: 1.5rem 2rem;
    z-index: 1000;
    background: #D6D5E3;
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    grid-template-areas: 'title''prev''sponsor';
    align-content: space-between;
    text-transform: uppercase;
    grid-row-gap: 5vh;
}

.frame__title {
    grid-area: title;
    display: flex;
}

.frame__title-main {
    font-size: 1rem;
    margin: 0;
    font-weight: normal;
    line-height: 1;
    align-self: center;
    text-align: right;
}

.frame__title-back {
    position: relative;
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.15rem;
}

.frame__title-back span {
    display: none;
}

.frame__title-back svg {
    fill: currentColor;
}

.frame__prev {
    grid-area: prev;
}

.preview-wrap {
    grid-area: page;
    padding: 0 2rem 3rem;
    position: relative;
    z-index: 100;
    background-image: url(/src/images/question-bg.webp);
    background-repeat: no-repeat;
    background-size: contain;
}

.contentv6-open .preview-wrap {
    pointer-events: none;
}

.preview {
    min-height: 400px;
    margin: 0 auto;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    will-change: transform;

    @media (max-width: 630px) {
        min-height: 250px;
    }
}

.preview__img-wrap {
    grid-area: 1 / 1 / -1 / -1;
}

.preview__img {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.preview__img-inner {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transform-origin: initial !important;

}

.contentv6__meta {
    margin-bottom: 2rem;
    color: var(--color-text-alt);
}

.preview__title {
    text-align: center;
    align-self: center;
    justify-self: center;
    grid-area: 1 / 1 / -1 / -1;
    position: relative;
    transform: translate3d(0, 50%, 0);
    pointer-events: none;
}

.preview__title-main {
    line-height: 120px;
    margin: 0;
    font-weight: 700;
    font-size: 80px;
}

.preview__title-main>span {
    display: block;
}

.preview__title-main>span:last-child {
    display: block;
    padding: 0 1rem;
    transform: translateY(-33%);
    // font-family: KalamehWeb;
    font-size: 80px;
    font-weight: 900;
    line-height: 110px;
    color: #FFF;

    @media (max-width: 950px) {
        font-size: 32px;
        line-height: 48px;
    }

    @media (max-width: 630px) {
        font-size: 20px;
        line-height: 30px;
    }
}

.preview {
    @media (max-width: 950px) {
        height: 300px !important;
        border-radius: 40px;
    }
}

.preview__desc {
    margin: 0 auto;
    max-width: 400px;
    text-transform: uppercase;
    line-height: 1.2;
}

.contentv6-wrap {
    display: none !important;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0;
    grid-area: page;
    height: 100vh;
    overflow: hidden;
    display: grid;
    grid-template-areas:
        'back back'
        'content content';
    grid-template-rows: auto 1fr;
    pointer-events: none;
    padding: 2rem;
}

.action {
    visibility: hidden;
    pointer-events: none;
    opacity: 0;
}

.contentv6-open .action {
    visibility: visible;
    pointer-events: auto;
}

.action svg {
    width: 2em;
    height: 2em;
}

.action--back {
    justify-self: start;
    grid-area: back;
    padding: 1rem 0;
}

.action span {
    text-transform: uppercase;
}

.contentv6 {
    grid-area: content;
    grid-row: 2 / span 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    pointer-events: none;
    opacity: 0;
    display: grid;
    grid-gap: 2vw;
    grid-template-columns: 100%;
    grid-template-areas:
        'img'
        'thumbs'
        'text';
    grid-template-rows: 20vh min-content auto;
}

.contentv6--current {
    position: relative;
    pointer-events: auto;
    opacity: 1;
}

.contentv6__group {
    grid-area: text;
}

.contentv6__title {
    font-family: scotch-display, sans-serif;
    font-weight: 400;
    font-size: clamp(3rem, 6.5vw, 5.5rem);
}

.contentv6__title span {
    display: block;
}

.contentv6__title>span:last-child {
    transform: translateY(-25%);
    font-family: scotch-display, sans-serif;
    font-weight: 500;
    font-style: italic;
    padding: 0 0 0 0.75rem;
}

.contentv6 .preview__img {
    grid-area: img;
}

.contentv6__thumbs {
    grid-area: thumbs;
    display: grid;
    grid-auto-columns: auto;
    grid-auto-flow: column;
    grid-gap: 2vw;
    justify-content: start;
}

.contentv6__thumbs-item {
    width: 50px;
    aspect-ratio: 1.5;
}

.contentv6__thumbs-item:hover {
    cursor: not-allowed;
}

.hidden {
    opacity: 0;
    pointer-events: none;
}

@media screen and (min-width: 53em) {
    .frame {
        position: -webkit-sticky;
        /* Safari */
        position: sticky;
        top: 0;
        height: 100vh;
        opacity: 0.5;
        padding: 2rem;
    }

    .frame__title,
    .frame__prev,
    #cdawrap {
        justify-self: start;
        -webkit-writing-mode: vertical-rl;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
    }

    body #cdawrap {
        justify-self: start;
    }

    .frame__prev {
        margin: 1rem 0 0;
    }

    .frame__title-back {
        transform: scale3d(1, -1, 1);
    }

    .preview {
        height: 620px;
        border-radius: 40px;
    }

    .preview__desc {
        margin: -2.5rem auto 0;
    }

    .contentv6 {
        grid-template-columns: 60% 1fr;
        grid-template-areas:
            '... ...'
            'img text'
            'thumbs text'
            '... ...';
        grid-template-rows: 2rem 1fr auto 1rem;
    }

    .contentv6__thumbs-item {
        width: 100px;
    }

    .contentv6__text {
        max-width: 400px;
    }
}

//Fade Backgound Change Color
.color-violet {
    background-color: #D6D5E3;
}

.color-indigo {
    background-color: #EFDBD0;
}

.color-blue {
    background-color: #1b1b1b;
}

.color-green {
    background-color: #E8EDEE;
}

.button {
    position: relative;
}

.active {
    cursor: pointer;
}


//Wave Aninmation
.wave-text {
    direction: ltr;
    font-size: 128px;
    font-weight: 600;
    color: #202020;
    opacity: 0.1199999973;
    display: inline-block;
    white-space: pre;
    overflow: hidden;
    position: relative;
    height: 1.13em;
    font-family: 'poppins';

    @media (max-width: 920px) {
        font-size: 64px;
        line-height: 64px;
        height: 0.75em;
    }

}

.wave-text span {
    position: relative;
    display: inline-block;
    animation: wave 1.5s infinite ease-in-out;
}

@keyframes wave {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

/* Delay the animation start for each character */
.wave-text span:nth-child(1) {
    animation-delay: 0.1s;
}

.wave-text span:nth-child(2) {
    animation-delay: 0.2s;
}

.wave-text span:nth-child(3) {
    animation-delay: 0.3s;
}

.wave-text span:nth-child(4) {
    animation-delay: 0.4s;
}

.delay-2 {
    animation-delay: 3s;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }

    100% {
        transform: translateX(0);
    }
}

#farsi-title span {
    animation: slideInFromLeft 1s ease-out;
    visibility: hidden;
}

.waveh-text {
    direction: ltr;
    white-space: pre;
    overflow: hidden;
    position: relative;
    height: 1.28em;
    margin-bottom: 16px;
    color: #202020;
    font-size: 80px;
    font-weight: bold;
    line-height: 120px;
    font-family: 'poppins';

    @media (max-width: 720px) {
        margin-bottom: 8px;
        font-size: 32px;
        line-height: 48px;
    }
}

.wave-text-right {
    @media (max-width: 720px) {
        text-align: left;
    }
}

.waveh-text-color {
    margin-bottom: 80px;
    color: #FFF;
    font-size: 80px;
    font-weight: 700;
    line-height: 120px;

    @media (max-width: 720px) {
        margin-bottom: 32px;
        font-size: 32px;
        line-height: 48px;
        text-align: left;
    }
}

.waveh-text-colorv2 {
    color: #FFF;
    font-size: 80px;
    font-weight: 700;
    line-height: 120px;
    margin-bottom: 80px;
    text-align: center;

    @media (max-width: 720px) {
        margin-bottom: 48px;
        font-size: 32px;
        line-height: 48px;
    }
}

.waveh-text-colorv3 {
    color: #1b1b1b;
    font-size: 80px;
    font-weight: 700;
    line-height: 128px;
    margin-bottom: 80px;

    @media (max-width: 720px) {
        font-size: 32px;
        line-height: 48px;
        margin-bottom: 48px;
    }
}

.waveh-text span {
    position: relative;
    display: inline-block;
    animation: wave 2s infinite ease-in-out;
}

@keyframes wave {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.waveh-text span:nth-child(1) {
    animation-delay: 0.1s;
}

.waveh-text span:nth-child(2) {
    animation-delay: 0.2s;
}

.waveh-text span:nth-child(3) {
    animation-delay: 0.3s;
}

.waveh-text span:nth-child(4) {
    animation-delay: 0.4s;
}


//Magnete Button 
.buttonv1 {
    cursor: pointer;
    padding: 16px 32px;
    margin: 0 auto;
    background-color: #FFF;
    border-radius: 32px;
    border: none;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000;
    font-size: 20px;
    font-weight: 900;
    line-height: 32px;

    @media (max-width: 720px) {
        padding: 12px 24px;
        font-size: 16px;
    }
}  

.animate__animated.animate__fadeInUp {
    --animate-duration: 2s;
}
